var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Kategori")]),_c('div',{staticClass:"card-header-actions"},[_c('small',{domProps:{"textContent":_vm._s(_vm.modeCategory === 'new' ? 'Yeni' : 'Düzenle')}})])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CInput',{attrs:{"value":_vm.formData.title,"label":"Kategori Adı","placeholder":"Kategori Adı"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "title", $event)}}})],1),_c('CCol',{attrs:{"sm":"2"}},[_c('CInput',{attrs:{"value":_vm.formData.o,"label":"Sıra No","placeholder":"Sıra No"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "o", $event)}}})],1)],1)],1)],1),_c('hr'),_c('CRow',[_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[(_vm.modeCategory !== 'new')?_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.updateCategory(_vm.id)}}},[_vm._v("Kaydet")]):_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.createCategory()}}},[_vm._v("Yeni Ekle")]),_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.cleanCategory()}}},[_vm._v("Temizle")])],1)],1)],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Kategoriler")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"columnFilter":true,"hover":true,"striped":true,"items":_vm.companyCategories,"fields":[
              {
                key: 'id',
                label: 'ID',
              },
              {
                key: 'o',
                label: 'Sıra',
              },
              {
                key: 'title',
                label: 'Kategori Adı',
              },
              {
                key: 'actions',
                label: 'Aksiyonlar',
              } ],"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.editCategory(item)}}},[_vm._v("Düzenle")]),_c('CButton',{attrs:{"size":"sm","variant":"outline","color":"danger"},on:{"click":function($event){return _vm.removeCategory(item.id)}}},[_vm._v("Sil")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }